<template>

  <section>
    <div class="position-relative">
      <img
        class="img-fluid"
        src="@/assets/img/banner-equipo-y-experiencia-desktop.webp"
        alt="Imagen de comienzo de seccion equipo y experiencia"
        loading="lazy"
      />
      <div class="DivBannerEjecutivo">
        <div class="DivBannerEjecutivoTexto">
      <div class="form wet-form">
      <h2>
        <span style="color: #14993c"> Español </span>
      </h2>
        <input type="text" class="form-control form-titulo" v-model="titleES"/>
        <input type="text" class="form-control form-control-sm form-subtitulo" v-model="subtitleES"/>
        <button class="btn btn-success btn-lg mt-3" @click="syncContent('ES')">Guardar</button>
      </div>
        </div>
      </div>
    </div>
      <hr class="pill mb-3">

    <div class="position-relative">
      <img
        class="img-fluid"
        src="@/assets/img/banner-equipo-y-experiencia-desktop.webp"
        alt="Imagen de comienzo de seccion equipo y experiencia"
        loading="lazy"
      />
      <div class="DivBannerEjecutivo">
        <div class="DivBannerEjecutivoTexto">
      <div class="form wet-form">
      <h2>
        <span style="color: #14993c"> English </span>
      </h2>
        <input type="text" class="form-control form-titulo" v-model="titleEN"/>
        <input type="text" class="form-control form-control-sm form-subtitulo" v-model="subtitleEN"/>
        <button class="btn btn-success btn-lg mt-3" @click="syncContent('EN')">Save</button>
      </div>
        </div>
      </div>
    </div>
  </section>


</template>


<script>
import { getSection, putSection } from '../../helpers/contenido'
import store from '../../store';

export default {
  name: "EquipoBanner",
  data() {
    return {
        titleES: 'Equipo y Experiencia',
        titleEN: 'Equipo y Experiencia',
        subtitleES: 'Te invitamos a conocernos',
        subtitleEN: 'Te invitamos a conocernos'
    }
  },
  mounted() {
    this.fetchData();
    this.fetchContent();
  },
  watch: {
    "$store.state.sections": function () {
      this.fetchData();
    }
  },
  methods: {
    fetchData() {
      if (store.state.sections.teamExperience) {
        this.data = store.state.sections.teamExperience.components
      }
    },

    async fetchContent() {
      const section = await getSection("teamExperience");
      const sectionEs = section.filter( (section) => section.language == "ES"  )
      const sectionEn = section.filter( (section) => section.language == "EN"  )
      this.sectionEs = sectionEs
      console.log(section)
      this.sectionEn = sectionEn
      this.titleES = sectionEs[0].components.title
      this.subtitleES = sectionEs[0].components.subtitle
      this.titleEN = sectionEn[0].components.title
      this.subtitleEN = sectionEn[0].components.subtitle
    },

    async syncContent(lan) {
      if (lan === "ES") {
        const words = {
            id: this.sectionEs[0]._id,
            section: "teamExperience",
            components: {
                title: this.titleES,
                subtitle: this.subtitleES,
            },
            language: "ES"
        }
        await putSection(words);
      } else {
        const words = {
            id: this.sectionEn[0]._id,
            section: "teamExperience",
            components: {
                title: this.titleEN,
                subtitle: this.subtitleEN,
            },
            language: "EN"
        }
        await putSection(words);
      }
    }
  }
}
</script>

<style scoped lang="scss">
/* .teamBanner {
  height: 280px;
  width: 100%;
  position: relative;

  .__content {
    background-image: url("../../assets/img/left-grey-oval.png");
     background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom left;
    height: 280px;
    max-width: 1400px;
    width: 100%;
    padding: 20px;
    position: relative;

    img {
      bottom: 0;
      position: absolute;
      height: 240px;
      max-height: 600px;
      right: 5%;
    }
  }
}

@media (min-width: 992px) {
  .teamBanner {
    align-items: center;
    display: flex;
    height: 580px;
    justify-content: flex-end;
    margin-top: 11rem;

    .__content {
      align-items: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: bottom left;
      display: flex;
      justify-content: flex-start;
      height: 380px;
      width: 100%;
      padding: 0 0 0 13%;

      .__subtitle {
        max-width: 30%;
      }

      img {
        height: auto;
      }
    }

    h2 {
      font-size: 3.1rem !important;
    }
  }
} */

.form-subtitulo{
  color: #14993c;
}

.ModeloBanner {
  position: relative;
}

.DivBannerEjecutivo {
  position: absolute; /* posición absolute con respecto al padre */
  top: 13%;
  width: 40%;
  height: 59%;
  left: 12%;
  /*background-color: #575da6;*/
  /*padding: 1%;*/
}

.DivBannerEjecutivoTexto {
  padding: 1%;
  margin-top: 24%;
}

.models-image {
  margin-right: -1rem;
}

.titulo-idioma{
  margin-left: 8rem;
}

hr.pill {
  height:0px;
  margin: auto auto;
  border-radius: 2px;
  color: #14993c !important;
  border: 2px solid currentColor;
  width: 80%;
}
</style>
